<template>
    <div>
        <NavBar :isHome='false' />
         <div class="container-fluid company-banner">
            <div>
                <p class="company-banner-title">NSService</p>
                <p class="company-banner-desc">日新！毎日新しい自分を発現し、新しい世界と出会う。</p>
            </div>
        </div>
        <div class="sub-nav-container-company container-fluid">
            <div @click="[subNavSelected = index,$router.push(item.url)]" class="company-sub-nav-text" :class="subNavSelected === index?'company-sub-nav-text-active':''" v-for="(item, index) in subNavBarData" :key="index">
                {{item.text}}
                <div
                :class="subNavSelected === index?'company-sub-nav-border-active':''"
                class="company-sub-nav-border"></div>
            </div>
        </div>
        <router-view></router-view>
    </div>
</template>

<script>
/* eslint-disable */
import NavBar from '@/components/NavBar'
export default {
    components:{
        NavBar
    },
    data(){
        return {
            subNavSelected:0,
            subNavBarData:[
                {
                    index:0,
                    text:'会社概要',
                    name:'company',
                    url:'/company',
                },
                {
                    index:1,
                    text:'事業戦略',
                    name:'strategy',
                    url:'/company/strategy',
                },
                {
                    index:2,
                    text:'開発実績例',
                    name:'instance',
                    url:'/company/instance',
                }
            ]
        }
    },
    mounted() {
        this.subNavInit()
    },
    methods: {
        subNavInit(){
            this.subNavSelected = this.subNavBarData.find(k => k.name === this.$route.name).index
        }
    },
}
</script>

<style>
@media screen and (min-width:768px) and (max-width:1920px){
    .company-banner{
        background-image:url('../assets/images/company/banner8.png');
    }
    @media screen and (max-width:768px) {
        .company-banner{
            height: 125px !important;
            height: calc(100vw/1920*640) !important;
        }
    }
    .company-banner{
        margin-top: 20px;
        height: calc(100vw/1920*400);
        background-size: contain;
        background-repeat: no-repeat;
    }
    .company-banner div{
        width: calc(100vw/1920*554);
        text-align: left;
        position: absolute;
        top: calc(100vw/1920*192);
        left:calc(100vw/1920*360);
    }
    .company-banner-title{
        font-size:calc(100vw/1920*30);
        font-family:Noto Serif JP,YuGothic,serif;
        font-weight:700;
        color:rgba(255,255,255,1);
        line-height:60px;
        text-shadow:0px 2px 2px rgba(0,0,0,0.15);
    }
    .company-banner-desc{
        font-size:calc(100vw/1920*16);
        font-family:Noto Serif JP,YuGothic,serif;;
        font-weight:400;
        color:rgba(255,255,255,1);
        line-height:32px;
        text-shadow:0px 1px 1px rgba(0,0,0,0.5);
    }
    .sub-nav-container-company{
        height: 80px;
        display: flex;
        padding:0 calc(100vw/1920*360);
        border-bottom: 1px solid #DDDDDD;
    }
    .company-sub-nav-text{
        cursor: pointer;
        font-size:calc(100vw/1920*24);
        font-family:Noto Serif JP,YuGothic,serif;;
        font-weight:400;
        line-height:calc(100vw/1920*33);
        width:168px;
        line-height: 76px;
        font-family:Noto Serif JP,YuGothic,serif;;
        font-weight:400;
        width: 100vw;

    }
    .company-sub-nav-border{
        width: 0;
        margin: 0 auto;
        border-bottom: 4px solid #FFF;
    }
    .company-sub-nav-text-active{
        color:rgba(2,98,255,1);
        transition: all 0.5s;
    }
    .company-sub-nav-border-active{
        width: 100px;
        transition: all 0.5s;
        border-bottom: 4px solid #0262FF;
    }
}
@media screen and (max-width:768px){
    .company-banner{
        background-image:url('../assets/images/company/banner8_mb.png');
        margin-top: 20px;
        height: 140px;
        background-size: contain;
        background-repeat: no-repeat;
    }
    .company-banner div{
        /* width: 100%; */
        text-align: left;
        position: absolute;
        top: 40px;
        left:60px;
    }
    .company-banner-title{
        position: relative;
        top: 60px;
        font-family:Noto Serif JP,YuGothic,serif;
        font-weight:700;
        color:rgba(255,255,255,1);
        line-height:30px;
        text-shadow:0px 2px 2px rgba(0,0,0,0.15);
    }
    .company-banner-desc{
        display: none;
        font-size:calc(100vw/1920*16);
        font-family:Noto Serif JP,YuGothic,serif;;
        font-weight:400;
        color:rgba(255,255,255,1);
        line-height:32px;
        text-shadow:0px 1px 1px rgba(0,0,0,0.5);
    }
    .sub-nav-container-company{
        height: 80px;
        display: flex;
        border-bottom: 1px solid #DDDDDD;
    }
    .company-sub-nav-text{
        cursor: pointer;
        font-size:12px;
        font-family:Noto Serif JP,YuGothic,serif;;
        font-weight:400;
        line-height:14px;
        width:168px;
        line-height: 76px;
        font-family:Noto Serif JP,YuGothic,serif;;
        font-weight:400;
        width: 100vw;

    }
    .company-sub-nav-border{
        width: 0;
        margin: 0 auto;
        border-bottom: 4px solid #FFF;
    }
    .company-sub-nav-text-active{
        color:rgba(2,98,255,1);
        transition: all 0.5s;
    }
    .company-sub-nav-border-active{
        width: 100px;
        transition: all 0.5s;
        border-bottom: 4px solid #0262FF;
    }
}
@media screen and (min-width:1920px){
    .company-banner{
        background-image:url('../assets/images/company/banner8.png');
    }
    .company-banner{
        margin-top: 20px;
        height: 400px;
        background-size: contain;
        background-repeat: no-repeat;
    }
    .company-banner div{
        width: 554px;
        text-align: left;
        position: absolute;
        top: 192px;
        left:360px;
    }
    .company-banner-title{
        font-size:30px;
        font-family:Noto Serif JP,YuGothic,serif;
        font-weight:700;
        color:rgba(255,255,255,1);
        line-height:60px;
        text-shadow:0px 2px 2px rgba(0,0,0,0.15);
    }
    .company-banner-desc{
        font-size:16px;
        font-family:Noto Serif JP,YuGothic,serif;;
        font-weight:400;
        color:rgba(255,255,255,1);
        line-height:32px;
        text-shadow:0px 1px 1px rgba(0,0,0,0.5);
    }
    .sub-nav-container-company{
        height: 80px;
        display: flex;
        padding:0 360px;
        border-bottom: 1px solid #DDDDDD;
    }
    .company-sub-nav-text{
        cursor: pointer;
        font-size:24px;
        font-family:Noto Serif JP,YuGothic,serif;;
        font-weight:400;
        line-height:33px;
        width:168px;
        line-height: 76px;
        font-family:Noto Serif JP,YuGothic,serif;;
        font-weight:400;
        width: 100vw;

    }
    .company-sub-nav-border{
        width: 0;
        margin: 0 auto;
        border-bottom: 4px solid #FFF;
    }
    .company-sub-nav-text-active{
        color:rgba(2,98,255,1);
        transition: all 0.5s;
    }
    .company-sub-nav-border-active{
        width: 100px;
        transition: all 0.5s;
        border-bottom: 4px solid #0262FF;
    }
}
</style>